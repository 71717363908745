<template lang="pug">
  
.container(v-loading.fullscreen.lock='loading')
    el-card.clickCard(shadow='hover' v-show='clickVisible')
      h2.title 
      h2.title FUTURE VISION LAB
      h2.title 《 Re-Generative::Morph() 》
      //- .bt
      //- el-button( @click='sendClick' type="primary" plain) {{ clickText }}
      .block 
      .demonstration Drag the fader 페이더를 드래그하세요
      
      .block
      span.demonstration
      el-slider(v-model='packageObj.value1' @change='onChangeS1' :show-tooltip="false" style='margin-top:1em')
      el-slider(v-model='packageObj.value2' @change='onChangeS2' :show-tooltip="false" style='margin-top:1.3em')
      el-slider(v-model='packageObj.value3' @change='onChangeS3' :show-tooltip="false" style='margin-top:1.3em')
      el-slider(v-model='packageObj.value4' @change='onChangeS4' :show-tooltip="false" style='margin-top:1.3em')

      .demonstration Select each color 각 색상을 선택하세요.

      .block(style='margin-top:2em')
      span.demonstration
      el-color-picker(v-model='packageObj.color1' show-alpha='' @change='onChangeC1' style='margin-right:0.5em')
      el-color-picker(v-model='packageObj.color2' show-alpha='' @change='onChangeC2' style='margin-right:0.5em')
      el-color-picker(v-model='packageObj.color3' show-alpha='' @change='onChangeC3' style='margin-right:0.5em')
      el-color-picker(v-model='packageObj.color4' show-alpha='' @change='onChangeC4' style='margin-right:0.5em')
      el-color-picker(v-model='packageObj.color5' show-alpha='' @change='onChangeC5')

      .bt
        el-button( @click='reset' type="primary" style='margin-top:2em' plain)
          | re-Generate!  
          i(class="el-icon-refresh-right")

</template>

<script>
export default {
  name: 'Home',
  components: {
    // Tone,
    // verte,
    // LvInput,
    // lvButton,
  },
  data() {
    return {
      initObject: {
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        color1: '#409EFF',
        color2: '#1AEBB3',
        color3: '#653bff',
        color4: '#f7ff12',
        color5: '#eb1a36',
      },
      packageObj: {
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        color1: '#B407EA',
        color2: '#1AEBB3',
        color3: '#653bff',
        color4: '#f7ff12',
        color5: '#eb1a36',
      },
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      socket: '',
      loading: false,
      checkVisible: true,
      infoVisible: true,
      timeout: null,
      currentTime: 0,
      bClick: false,
      characterVisible: true,
      clickVisible: true,
      selected: { index: 1 },
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:7070', {
      // eslint-disable-next-line no-undef
      this.socket = io('https://re-generative.clab.org.tw:7070', {
      withCredentials: true,
      extraHeaders: {
        'finger-drumming-play': 'abcd',
      },
      query: {
        client: 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
      this.socket.emit('onReady');
    });

    this.socket.on('syncCurrentValue', (data) => {
      this.packageObj = data;
      console.log('syncCurrentValue :>> ', this.packageObj);
      // console.log('syncCurrentValue', this.packageObj);
    });

    // ------------------Slider---------------------
    this.socket.on('userChangeValue1', (value) => {
      this.packageObj.value1 = value;
      console.log('changed', this.packageObj.value1);
    });
    this.socket.on('userChangeValue2', (value) => {
      this.packageObj.value2 = value;
      console.log('changed', this.packageObj.value2);
    });
    this.socket.on('userChangeValue3', (value) => {
      this.packageObj.value3 = value;
      console.log('changed', this.packageObj.value3);
    });
    this.socket.on('userChangeValue4', (value) => {
      this.packageObj.value4 = value;
      console.log('changed', this.packageObj.value4);
    });
    //-------------------ColorPicker------------------
    this.socket.on('userChangeColor1', (color) => {
      this.packageObj.color1 = color;
      console.log('color1Changed', this.packageObj.color1);
    });
    this.socket.on('userChangeColor2', (color) => {
      this.packageObj.color2 = color;
      console.log('color2Changed', this.packageObj.color2);
    });
    this.socket.on('userChangeColor3', (color) => {
      this.packageObj.color3 = color;
      console.log('color3Changed', this.packageObj.color3);
    });
    this.socket.on('userChangeColor4', (color) => {
      this.packageObj.color4 = color;
      console.log('color4Changed', this.packageObj.color4);
    });
    this.socket.on('userChangeColor5', (color) => {
      this.packageObj.color5 = color;
      console.log('color5Changed', this.packageObj.color5);
    });
  },
  destroyed() {
    this.socket.disconnect();
  },
  methods: {
    // ConvertHex (color1) {
    //     color1 = color1.replace('#', '')
    //     const r = parseInt(color1.substring(0, 2), 16)
    //   const g = parseInt(color1.substring(2, 4), 16)
    //   const b = parseInt(color1.substring(4, 6), 16)
    //   return `rgba(${r}, ${g}, ${b}, ${this.opacity / 100})`
    //   },

    start() {
      this.infoVisible = false;
      this.socket.emit('onMobileConnected');
      setTimeout(() => {
      }, 500);
    },

    sendCharacter(obj) {
      this.selected = obj;
      this.characterVisible = false;
      this.clickVisible = true;
      this.scrollToTop();
      this.socket.emit('sendCharacter', this.selected.index);
      console.log('sendCharacter :>> ', this.selected.index);
    },

    reset() {
      // this.bClick = !this.bClick;
      // // this.packageObj = this.initObject;
      // this.packageObj.value1 = 0;
      // this.packageObj.value2 = 0;
      // this.packageObj.value3 = 0;
      // this.packageObj.value4 = 0;
      // this.packageObj.color1 = '#B407EA';
      // this.packageObj.color2 = '#1AEBB3';
      // this.packageObj.color3 = '#653bff';
      // this.packageObj.color4 = '#f7ff12';
      // this.packageObj.color5 = '#eb1a36';

      this.socket.emit('user_reset_btn', 1);
      console.log('re-Generative', 1);
    },

    onChangeS1() {
      // const color1 = this.packageObj.color1
      // let rgba = color1
      // this.rgba = rgba(r,g,b)
      // const r = parseInt(color1.substring(0, 2), 16)
      // const g = parseInt(color1.substring(2, 4), 16)
      // const b = parseInt(color1.substring(6, 8), 16)
      // this.socket.emit('user_reset_btn',packageObj);
      // ----------------------Slider-----------------------------------
      this.socket.emit('user_s1', this.packageObj.value1);
      console.log('onChangeValue :>>', this.packageObj.value1);
      // console.log('onChange :>> ', this.packageObj.color1)

      // this.socket.emit('user_s2',this.packageObj.value2)
      // this.socket.emit('user_s3',this.packageObj.value3)
      // this.socket.emit('user_s4',this.packageObj.value4)

      // ----ColorExample------
      // var cColor = covertColor(this.packageObj.color1);
      // this.socket.emit('color1', cColor)
      // console.log('onChange :>> ', this.packageObj);
    },

    onChangeS2() {
      this.socket.emit('user_s2', this.packageObj.value2);
      console.log('onChangeValue :>>', this.packageObj.value2);
    },
    onChangeS3() {
      this.socket.emit('user_s3', this.packageObj.value3);
      console.log('onChangeValue :>>', this.packageObj.value3);
    },
    onChangeS4() {
      this.socket.emit('user_s4', this.packageObj.value4);
      console.log('onChangeValue:>>', this.packageObj.value4);
    },
    // -------------------ColorPicker------------------------------------
    onChangeC1() {
      this.socket.emit('user_cc1', this.packageObj.color1);
      console.log('onChangeColor1 :>>', this.packageObj.color1);
    },
    onChangeC2() {
      this.socket.emit('user_cc2', this.packageObj.color2);
      console.log('onChangeColor2 :>>', this.packageObj.color2);
    },
    onChangeC3() {
      this.socket.emit('user_cc3', this.packageObj.color3);
      console.log('onChangeColor3 :>>', this.packageObj.color3);
    },
    onChangeC4() {
      this.socket.emit('user_cc4', this.packageObj.color4);
      console.log('onChangeColor4 :>>', this.packageObj.color4);
    },
    onChangeC5() {
      this.socket.emit('user_cc5', this.packageObj.color5);
      console.log('onChangeColor5 :>>', this.packageObj.color5);
    },
  },

  scrollToTop() {
    window.scrollTo(0, 0);
  },

  formatTooltip(val) {
    return val / 100;
  },
};
</script>

<style lang="scss" scoped>
.container {
  // background-image: url('/img/bg.jpeg');
  background-color: #1d1d1d;
  height: auto;
  // min-height: 200vh;
  // width: 100vw;
  padding: 30px;
  color: rgb(255, 255, 255);

  .logo {
    width: 100%;
  }

  .title {
    color: rgb(255, 255, 255);
    margin: 0px;
  }

  .title-sub {
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .check {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }

  .el-card {
    margin-top: 20px;
    background-color: rgb(0 0 0 / 0%);
    border: 1px solid rgb(0 0 0 / 0%);
    color: rgb(255, 255, 255);
  }

  .d-flex {
    // display: flex;
    // background-color: black;
    height: auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 50% 50%;
    grid-column: 2;
    margin: 0 auto;
  }

  .outer {
    /* background-color: orange; */
    padding: 15px;
    height: 150px;
    margin: -28px;
  }

  .box {
    background-color: rgb(255, 255, 255);
    // flex:1;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
  }

  .character {
    // border-radius: 200px;
    width: 90%;
  }

  .clickCard {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #1d1d1d;
    color: black;
    .bt {
      margin-top: 2vh;
    }

    img {
      margin-top: 5vh;
      border-radius: 10px;
      width: 30vw;
    }
  }

  .clickButton {
    width: 65vw;
    height: 65vw;
    font-size: 4em;
    border-radius: 20px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.05s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .demonstration {
    margin-top: 2em;
    color: white;
  }

  .el-slider {
    width: 300px;
    margin: auto;
    color: white;
  }
}
</style>
